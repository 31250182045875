<template>
    <BT-Blade-Items
        addBladeName="user"
        bladeName="users"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        canShowInactive
        :headers="[
            { text: 'User Name', value: 'userName', display: true, searchable: true, title: 1 }, 
            { text: 'First Name', value: 'firstName', searchable: true }, 
            { text: 'Last Name', value: 'lastName', searchable: true },
            { text: 'Email', value: 'email', subtitle: 1, searchable: true },
            { text: 'Phone Number', value: 'phoneNumber', searchable: true }]"
        navigation="users"
        title="Users"
        useServerPagination>
        <template v-slot:userName="{ item }">
            {{  item.userName }}<v-icon v-if="item.isGlobalAdmin" right small title="Global Admin">mdi-earth</v-icon>
        </template>
        <template slot="settings">
            <v-list-item :to="{ name: 'users-board' }">
                <v-list-item-icon>
                    <v-icon small>mdi-view-dashboard-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Board</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Users-Blade',
    props: {
        bladesData: null
    }
}
</script>